<script setup lang="ts">
import {
  ChatCarrier,
  ChatHeadline,
  Content,
  Message,
  SelectedAnswersEmit,
} from '~/modules/chat-ad'

import chatAudioMessage from '~/assets/audios/chat-ad-es/chat-delay-message.mp3'
import medicareCard from '~/assets/card-medicare-no-shadow.png'
import emilyNewAvatar from '~/assets/emily-b008.png'
import emilyWithGroceries from '~/assets/emily-w-groceries.png'
import { PlanRowView } from '~/modules/plan'

const isPaidUser = ref(true)
const matches = ref(false)
const plans = ref<PlanRowView[]>([])
const loading = ref<boolean>(true)
const error = ref<boolean>(false)
const top5Otc = ref<number>(0)
const selectedAnswers = ref<SelectedAnswersEmit | null>(null)
const showZipCodeInput = ref(true)
const zipCode = ref<string | null>('')
const carriers = ref<ChatCarrier[]>([])

const afid = computed(() => {
  return typeof phoneConfig.value === 'object' && 'afid' in phoneConfig.value
    ? phoneConfig.value.afid
    : undefined
})

const queryAfid = ref<string | null>()

onMounted(() => {
  queryAfid.value = getSessionEnhanced().query.afid
})

const state = computed(() => {
  const state = states.find((state) => state.code === ip.lookup?.stateCode)

  if (state) {
    return state
  }
  return {
    name: 'tu área',
    medicaid: 'Medicaid',
    top5Otc: 0,
  }
})

onMounted(async () => {
  matches.value = window.matchMedia('(prefers-color-scheme: dark)').matches
  if (matches.value) {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }

  isPaidUser.value = getSessionEnhanced().isPaidUser

  await until(() => ip.lookup).toBeTruthy({ timeout: 5000 })

  zipCode.value = query.zip_code ?? ip.lookup?.postalCode ?? null

  enterExperiment('chatScript')

  if (zipCode.value) {
    showZipCodeInput.value = false
    try {
      await getPageData(zipCode.value)
    } catch (e) {
      error.value = true
    } finally {
      loading.value = false
    }
  } else {
    top5Otc.value = state.value?.top5Otc || 0
    showZipCodeInput.value = true
    loading.value = false
  }
})

const getPageData = async (zip: string) => {
  zipCode.value = zip
  try {
    plans.value = await getPlans({
      zipCode: zip,
      carrier_groups: [],
      amount: 1000,
      starting_from: 0,
    })
    await getTop5OtcData(zip, false)
  } catch (e) {
    error.value = true
  } finally {
    loading.value = false
    showZipCodeInput.value = false
  }
}

const getTop5OtcData = async (zip: string, is_dsnp: boolean) => {
  const response = await getTop5Otcs({
    zip_code: zip,
    is_dsnp: is_dsnp,
  })

  top5Otc.value = response.min_otc
    ? parseInt(`${response.min_otc}`, 10)
    : state.value?.top5Otc || 0
}

analytics.page({
  name: 'Chat Ad Page ES',
  category: 'LP',
  product: 'medicare',
})

const chatHeadline: ChatHeadline = {
  image: emilyNewAvatar,
  title: 'Asesora de Beneficios',
  subtitle: '¡Pregúntame sobre tus beneficios de Medicare!',
  advisorName: 'Emily',
}

const onSelectAnswer = async (
  nextStepId: string,
  selected: SelectedAnswersEmit,
  callback: (response: Message[]) => void
) => {
  selectedAnswers.value = {
    ...selectedAnswers.value,
    ...selected,
  }
  if (selected.haveMedicaid && selected.haveMedicaid === true) {
    const haveMedicaid = selected.haveMedicaid

    if (haveMedicaid === true && zipCode.value) {
      try {
        await getTop5OtcData(zipCode.value, haveMedicaid)
      } catch (e: any) {
        console.error('[DEBUG] error on retrieving new value for top5otc', {
          error: e.message,
        })
      }
    }
  }

  if (selected.zipcode) {
    zipCode.value = selected.zipcode
    await getPageData(selected.zipcode)
  }

  if (nextStepId === 'secondOpinion') {
    const data = await getTransferPhoneNumbers(afid, zipCode.value)
    carriers.value = data
  }

  const messages = getMessageBlockById(nextStepId)
  return callback(messages.filter(Boolean))
}

const getMessageBlockById = (stepId: string): Message[] => {
  return content.value.messageBlocks?.[stepId] ?? null
}

const isMobile = useIsMobile()

const content = computed<Content>(() => {
  const medicareStatus = selectedAnswers.value?.haveMedicare ? 'Sí' : 'No'

  return {
    messageBlocks: {
      init: [
        {
          type: 'text',
          content: '¡Hola!',
          from: 'emily',
          shouldShowAvatar: true,
        },
        {
          type: 'text',
          content: `¡Soy Emily de ${brand.name}!`,
          from: 'emily',
        },
        showZipCodeInput.value
          ? {
              type: 'text',
              content: `<span class="font-semibold">Para darte resultados personalizados, ¿podrías decirme tu código postal?</span>`,
              from: 'emily',
            }
          : null,
        showZipCodeInput.value
          ? {
              type: 'zip',
              answers: [
                {
                  text: 'Enviar',
                  answer: 'secondBlock',
                  event: { property: 'zipcode', value: null },
                },
              ],
            }
          : {
              type: 'redirect',
              from: 'emily',
              answers: [
                {
                  text: 'Enviar',
                  answer: 'secondBlock',
                  event: { property: 'zipcode', value: null },
                },
              ],
            },
      ],
      secondBlock: [
        isPaidUser.value && isMobile.value
          ? exp.value?.chatScript?.variantA
            ? {
                type: 'buttons',
                content: `<span class="font-bold">¿Quieres saber si calificas para una asignación de comestibles de ${USDollarFormatter(
                  0
                ).format(top5Otc.value)} o más? ¡Toca Sí! 😃</span>`,
                from: 'emily',
                answers: [
                  {
                    text: 'Sí',
                    answer: 'medicareab',
                    event: { property: 'groceryAllowance', value: true },
                  },
                ],
                clicked: false,
                trackPayload: {
                  top5Otc: top5Otc.value,
                },
              }
            : {
                type: 'image',
                image: emilyWithGroceries,
                from: 'emily',
              }
          : null,
        isPaidUser.value && isMobile.value && !exp.value?.chatScript?.variantA
          ? {
              type: 'buttons',
              content: `<span class="font-bold">¿Ya tienes tu tarjeta de asignación de ${USDollarFormatter(
                0
              ).format(top5Otc.value)}/año+?</span>`,
              from: 'emily',
              answers: [
                {
                  text: 'Sí 👍',
                  answer: 'medicareab',
                  event: { property: 'hasAllowanceCard', value: true },
                },
                {
                  text: 'No',
                  answer: 'medicareab',
                  event: { property: 'hasAllowanceCard', value: false },
                },
              ],
              clicked: false,
            }
          : null,

        !isPaidUser.value || !isMobile.value
          ? {
              type: 'buttons',
              content: `<span class="font-bold">¿Quiere aprender más sobre Medicare Advantage en ${state.value.name}?</span>`,
              from: 'emily',
              answers: [
                {
                  text: 'Sí 👍',
                  answer: 'medicareab',
                  event: { property: 'learnMoreMedicare', value: true },
                },
              ],
              clicked: false,
            }
          : null,
      ],
      medicareab: [
        selectedAnswers.value?.groceryAllowance === true ||
        selectedAnswers.value?.hasAllowanceCard === true ||
        selectedAnswers.value?.learnMoreMedicare === true
          ? {
              type: 'text',
              content: 'Sí',
              from: 'user',
            }
          : null,
        selectedAnswers.value?.hasAllowanceCard === false
          ? {
              type: 'text',
              content: 'No',
              from: 'user',
            }
          : null,
        {
          type: 'text',
          content:
            '<span class="font-bold">¿Tienes esta tarjeta? (¿Está usted en Medicare?)</span>',
          from: 'emily',
        },
        {
          type: 'image',
          image: medicareCard,
          from: 'emily',
        },
        {
          type: 'buttons',
          content: '',
          from: 'emily',
          answers: [
            {
              text: 'Sí',
              answer: 'medicaid',
              event: { property: 'haveMedicare', value: true },
            },
            {
              text: 'No',
              answer: 'medicaid',
              event: { property: 'haveMedicare', value: false },
            },
          ],
          clicked: false,
        },
      ].filter(Boolean),
      medicaid: [
        {
          type: 'text',
          content: medicareStatus,
          from: 'user',
        },
        {
          type: 'buttons',
          content: `<span class="font-bold">¿Está recibiendo beneficios de Medicaid${
            state.value.medicaid !== 'Medicaid'
              ? `, también conocido como ${state.value.medicaid}`
              : ''
          }?</span>`,
          from: 'emily',
          answers: [
            {
              text: 'Sí 👍',
              answer: selectedAnswers.value?.haveMedicare
                ? 'enrollBranch'
                : 'noMedicareAB',
              event: { property: 'haveMedicaid', value: true },
            },
            {
              text: 'No',
              answer: selectedAnswers.value?.haveMedicare
                ? 'enrollBranch'
                : 'noMedicareAB',
              event: { property: 'haveMedicaid', value: false },
            },
          ],
          clicked: false,
        },
      ],
      noMedicareAB: [
        {
          type: 'text',
          content: selectedAnswers.value?.haveMedicaid ? 'Sí' : 'No',
          from: 'user',
        },
        {
          type: 'text',
          content: 'Hmmm',
          from: 'emily',
        },
        {
          type: 'text',
          content: `Estabas preguntando por un beneficio de Medicare. No parece que tengas Medicare.`,
          from: 'emily',
        },
        {
          type: 'buttons',
          content: `<span class="font-bold">¿Cumplirá 65 años o se inscribirá en Medicare A&B en los próximos 6 meses?</span>`,
          from: 'emily',
          answers: [
            {
              text: 'Sí',
              answer: 'enrollBranch',
              event: { property: 'turning65', value: true },
            },
            {
              text: 'No',
              answer: 'notTurning65',
              event: { property: 'turning65', value: false },
            },
          ],
          clicked: false,
        },
      ],
      notTurning65: [
        {
          type: 'text',
          content: 'No',
          from: 'user',
        },
        {
          type: 'text',
          content: `Haga clic a continuación para obtener información sobre los planes ACA que pueden adaptarse mejor a sus necesidades.`,
          from: 'emily',
        },
        {
          type: 'redirectButton',
          content: `Más información`,
          from: 'emily',
          ctaUrl: '/go-4-benefits',
        },
      ],
      enrollBranch: [
        {
          type: 'text',
          content: selectedAnswers.value?.haveMedicaid ? 'Sí' : 'No',
          from: 'user',
        },
        {
          type: 'text',
          content: `¡Albricias!`,
          from: 'emily',
        },
        {
          type: 'text',
          content: `¡Parece que eres elegible!`,
          from: 'emily',
        },
        isPaidUser.value && isMobile.value
          ? {
              type: 'text',
              content: `¡Encontré planes cerca de ti con beneficios de ${USDollarFormatter(
                0
              ).format(top5Otc.value)}/año+ en subsidios para comestibles!`,
              from: 'emily',
            }
          : {
              type: 'text',
              content: `¡Encontré ${plans.value.length} planes cerca de ti!`,
              from: 'emily',
            },
        {
          type: 'buttons',
          content: `<span class="font-bold">¿Estaría dispuesto a hablar por teléfono para registrarse?</span>`,
          from: 'emily',
          answers: [
            {
              text: 'Sí',
              answer: 'callNow',
              event: { property: 'willingToTalkPhone', value: true },
            },
            {
              text: 'No',
              answer: 'enrollOnline',
              event: { property: 'willingToTalkPhone', value: false },
            },
          ],
          clicked: false,
        },
      ],
      redirectToClicks: [
        {
          type: 'redirectButton',
          content: `Comprar planes`,
          from: 'emily',
          ctaUrl: '/ads',
        },
      ],
      callNow: [
        {
          type: 'text',
          content: `Sí`,
          from: 'user',
        },
        {
          type: 'callButton',
          from: 'emily',
          shouldShowReminder: true,
        },
      ],
      enrollOnline: [
        {
          type: 'text',
          content: `No `,
          from: 'user',
        },
        {
          type: 'text',
          content: `¡Ningún problema!`,
          from: 'emily',
        },
        {
          type: 'text',
          content: `He encontrado algunas opciones que puedes explorar en línea.`,
          from: 'emily',
        },
        {
          type: 'redirectButton',
          content: `Comprar planes`,
          from: 'emily',
          ctaUrl: '/ads',
        },
      ],
      reminder: [
        {
          type: 'audio',
          content: '',
          audio: {
            src: chatAudioMessage,
            autoplay: true,
          },
          from: 'emily',
        },
      ],
      welcomeBack: [
        {
          type: 'text',
          content: 'Bienvenido de nuevo 👋',
          from: 'emily',
        },
        {
          type: 'buttons',
          content: '¿Cómo fue tu llamada?',
          from: 'emily',
          answers: [
            {
              text: 'Bien 👍',
              answer: 'goodCall',
              event: { property: 'goodCall', value: true },
            },
            {
              text: 'Mala 👎',
              answer: 'badCall',
              event: { property: 'goodCall', value: false },
            },
          ],
          clicked: false,
        },
      ],
      goodCall: [
        {
          type: 'text',
          content: 'Bien',
          from: 'user',
        },
        {
          type: 'text',
          content: 'Me alegro escuchar eso.',
          from: 'emily',
        },
        {
          type: 'buttons',
          content: '¿Encontraste lo que buscabas?',
          from: 'emily',
          answers: [
            {
              text: 'Sí 👍',
              answer: 'whatToDoNext',
              event: { property: 'foundWhatWasLookingFor', value: true },
            },
            {
              text: 'No 👎',
              answer: 'didntFindAfterGoodCall',
              event: { property: 'foundWhatWasLookingFor', value: false },
            },
          ],
          clicked: false,
        },
      ],
      badCall: [
        {
          type: 'text',
          content: selectedAnswers.value?.goodCall ? 'Bien' : 'Mala',
          from: 'user',
        },
        {
          type: 'text',
          content: `Lo siento mucho.`,
          from: 'emily',
        },
        {
          type: 'buttons',
          content: '¿Qué salió mal?',
          from: 'emily',
          answers: [
            {
              text: 'Tuve una mala experiencia',
              answer: 'whatToDoNext',
              event: { property: 'badExperience', value: true },
            },
            {
              text: `No tenían lo que quería`,
              answer: 'whatToDoNext',
              event: { property: 'foundWhatWasLookingFor', value: false },
            },
          ],
          clicked: false,
        },
      ],
      didntFindAfterGoodCall: [
        {
          type: 'text',
          content: `No`,
          from: 'user',
        },
        {
          type: 'text',
          content: `Lo siento mucho.`,
          from: 'emily',
        },
        {
          type: 'buttons',
          content: '¿Qué salió mal?',
          from: 'emily',
          answers: [
            {
              text: 'Mala experiencia',
              answer: 'whatToDoNext',
            },
            {
              text: `No tenían lo que yo quería`,
              answer: 'whatToDoNext',
            },
          ],
          clicked: false,
        },
      ],
      didntFind: [
        {
          type: 'text',
          content: `No tenían lo que yo quería`,
          from: 'user',
        },
        {
          type: 'text',
          content: `Lo siento mucho.`,
          from: 'emily',
        },
        {
          type: 'buttons',
          content: '¿Qué salió mal?',
          from: 'emily',
          answers: [
            {
              text: 'Mala experiencia',
              answer: 'whatToDoNext',
            },
            {
              text: `No tenían lo que yo quería`,
              answer: 'whatToDoNext',
            },
          ],
          clicked: false,
        },
      ],
      whatToDoNext: [
        selectedAnswers.value?.foundWhatWasLookingFor
          ? {
              type: 'text',
              content: 'Sí',
              from: 'user',
            }
          : {
              type: 'text',
              content: `No tenían lo que yo quería`,
              from: 'user',
            },
        {
          type: 'buttons',
          content: '¿Qué quieres hacer a continuación?',
          from: 'emily',
          answers: [
            {
              text: 'Obtenga una segunda opinión',
              answer: 'secondOpinion',
              event: { property: 'getSecondOpinion', value: true },
            },
            {
              text: `Explorar planes en línea`,
              answer: '',
              type: 'redirect',
              ctaUrl: '/ads',
              event: { property: 'explorePlansOnline', value: true },
            },
          ],
          clicked: false,
        },
      ],
      secondOpinion:
        carriers.value && carriers.value.length > 0
          ? [
              {
                type: 'carriers',
                content:
                  carriers.value && carriers.value.length > 0
                    ? '¡Lindo! Puede llamar a uno de los siguientes operadores:'
                    : `Lo siento, no pude encontrar ningún otro operador disponible ahora.`,
                from: 'emily',
                carriers: carriers.value,
              },
            ]
          : [
              {
                type: 'text',
                content: `Lo siento, no pude encontrar ningún otro operador disponible ahora.`,
                from: 'emily',
              },
              {
                type: 'text',
                content: `Pero he encontrado algunas opciones que puedes explorar en línea.`,
                from: 'emily',
              },
              {
                type: 'redirectButton',
                content: `Comprar planes`,
                from: 'emily',
                ctaUrl: '/ads',
              },
            ],
    },
    configuration: {
      id: 'chatAdEs',
      initialBlockId: 'init',
      reminder: {
        reminderBlockId: 'reminder',
        reminderTimeout: 10000,
      },
      postCallId: 'welcomeBack',
    },
  } as Content
})
</script>

<template>
  <Layout
    :hide-header="true"
    :hide-footer="true"
    class="bg-gradient-to-b from-white to-gray-100 dark:from-[#1f1f1f] dark:to-[#2d2d2d]"
  >
    <span
      class="absolute text-xs top-2 right-2 border border-black p-1 rounded bg-white"
    >
      Ad
    </span>
    <Chat
      v-if="content && !loading && !error"
      :content="content"
      :headline="chatHeadline"
      @on-select-answer="onSelectAnswer"
      :get-message-block-by-id="getMessageBlockById"
    />
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bridge", "bh", "m10"],
    "robots": "noindex"
  }
}
</route>
