<script setup lang="ts">
import Button from '~/components/Button.vue'

useHead({
  title: `Shop Medicare Plans Online - ${brand.name}`,
})

analytics.page({
  name: 'Get Offers',
  category: 'LP',
  product: 'medicare',
})

const isHovered = ref(false)
</script>

<template>
  <Layout hide-header footer="2024" class="container">
    <div
      class="min-h-screen bg-gradient-to-br from-blue-50 via-white to-blue-50 flex items-center justify-center p-4"
    >
      <div
        class="max-w-md w-full shadow-lg hover:shadow-xl transition-shadow duration-300 bg-white rounded-lg"
      >
        <div class="p-8">
          <div class="text-center space-y-8">
            <div class="relative">
              <div
                class="absolute inset-0 bg-blue-100 rounded-full opacity-20 animate-pulse"
              />
              <div class="mx-auto h-14 w-14 text-blue-600 relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-full w-full"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M20.618 5.984A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016zM12 9v2m0 4h.01"
                  />
                </svg>
              </div>
            </div>

            <div class="space-y-4">
              <h1 class="text-3xl font-bold text-gray-900 tracking-tight">
                Are you on Medicare?
              </h1>
              <p class="text-gray-600 text-lg">
                Click to Unlock Your Personalized Results
              </p>
              <Button
                variant="undefined"
                class="w-full flex-grow bg-blue-600 hover:bg-blue-700 text-white py-6 mt-2 text-xl font-medium rounded-lg transition-colors duration-300 flex items-center justify-center"
                to="/ads"
                @click="
                  analytics.track('learnMoreClicked', {
                    source: $route.fullPath,
                    destination: '/ads',
                  })
                "
                @mouseenter="isHovered = true"
                @mouseleave="isHovered = false"
              >
                <span>LET'S GO!</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 ml-2 transition-transform duration-300 hover:translate-x-1"
                  :class="{ 'translate-x-1': isHovered }"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "bridge"],
    "robots": "noindex"
  }
}
</route>
